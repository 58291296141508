import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

export const useAmountErrors = amount => {
  const intl = useIntl();
  const [amountError, setAmountError] = useState(null);

  useEffect(() => {
    const amountNumber = parseFloat(amount);

    if (amountNumber < 0.5) {
      setAmountError(
        intl.formatMessage(
          { id: 'payments.amount_small_error' },
          { limit: 0.5 },
        ),
      );
    } else if (amountNumber >= 1000000) {
      setAmountError(
        intl.formatMessage(
          { id: 'payments.amount_big_error' },
          { limit: 1000000 },
        ),
      );
    } else {
      setAmountError(null);
    }
  }, [amount]);

  return amountError;
};
