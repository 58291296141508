import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import TaskLogTable from './TaskLogTable';
import TaskLogPayload from './TaskLogPayload';
import { getTaskLogByTaskId } from '../../../../../crud/application.crud';
import CustomSnackbar from '../../../../../ui/CustomSnackbar';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '80%',
    top: '30%',
    left: '10%',
    maxHeight: '500px',
    overflowY: 'scroll',
  },
}));

export default function TaskLogModal({ task }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <div>
      {task.logs && task.logs.some(log => !log.isCorrect) ? (
        <OverlayTrigger
          trigger="hover"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Popover id={`popover-positioned-left`}>
              <Popover.Title as="h3">
                <FormattedMessage id="tasks.log.incorrect" />
              </Popover.Title>
            </Popover>
          }
        >
          <Button variant="danger" onClick={() => setOpen(true)}>
            <FormattedMessage id="tasks.log.view" />
          </Button>
        </OverlayTrigger>
      ) : (
        <Button variant="primary" onClick={() => setOpen(true)}>
          <FormattedMessage id="tasks.log.view" />
        </Button>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.paper}>
          <TaskLogPayload jsonString={task.payload} />
          <TaskLogTable logs={task.logs} />
        </div>
      </Modal>
    </div>
  );
}
