import { getTasksByApplicationId } from '../../crud/application.crud';

export const reducer = (
  state = {
    isApplicationDeletedMessage: false,
    tasksWithLogs: [[], 0],
    isSettingsChanged: false,
    application: null,
  },
  action,
) => {
  switch (action.type) {
    case 'SHOW_DELETE_MESSAGE':
      return { ...state, isApplicationDeletedMessage: action.payload };

    case 'SET_TASK_AND_LOGS':
      return { ...state, tasksWithLogs: action.payload };

    case 'SET_APPLICATION':
      return { ...state, application: action.payload };

    case 'SET_IS_SETTINGS_CHANGED':
      return { ...state, isSettingsChanged: action.payload };

    default:
      return state;
  }
};

export const actions = {
  setIsApplicationDeletedMessage: bool => ({
    type: 'SHOW_DELETE_MESSAGE',
    payload: bool,
  }),
  setTasksAndLogs: tasksWithLogs => ({
    type: 'SET_TASK_AND_LOGS',
    payload: tasksWithLogs,
  }),
  setApplication: application => ({
    type: 'SET_APPLICATION',
    payload: application,
  }),
  setIsSettingsChanged: bool => ({
    type: 'SET_IS_SETTINGS_CHANGED',
    payload: bool,
  }),
  fetchTasks: (id, pageNumber, limit) => async dispatch => {
    const tasks = await getTasksByApplicationId(id, pageNumber, limit);
    if (tasks) {
      dispatch(actions.setTasksAndLogs(tasks));
    } else {
      console.log('server error');
    }
  },
};
