import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { getUsersByApplicationId } from '../../../../crud/application.crud';
import InviteUserModal from './InviteUserModal';
import Pagination from '../taskList/TaskTable/Pagination';
import NoContent from '../../../../ui/NoContent';
import InviteLink from './InviteLink';
import UserRow from './UserRow';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    minWidth: 400,
  },
  table: {
    overflowX: 'scroll',
  },
  noUsers: {
    textAlign: 'center',
    fontSize: '2rem',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Users = ({ id, application, setIsErrorMessageOpen }) => {
  const classes = useStyles();
  const [usersData, setUsersData] = useState([]);
  const [count, setCount] = useState(0);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fetchUsers = async (page, usersPerPage) => {
    const usersAndCount = await getUsersByApplicationId(id, page, usersPerPage);
    if (usersAndCount) {
      const [usersData, count] = usersAndCount;
      setUsersData(usersData);
      setCount(count);
    } else {
      setIsErrorMessageOpen(true);
    }
  };

  useEffect(() => {
    fetchUsers(1, rowsPerPage);
  }, []);

  useEffect(() => {
    fetchUsers(1, rowsPerPage);
  }, [application]);

  return (
    <div>
      <InviteUserModal
        open={isInviteModalOpen}
        setOpen={setIsInviteModalOpen}
        applicationId={id}
      />
      <div className={classes.buttonWrapper}>
        <div className="d-flex col-lg-8 col-md-10">
          <InviteLink
            application={application}
            setIsErrorMessageOpen={setIsErrorMessageOpen}
          />
        </div>
        <Button color="primary" onClick={() => setIsInviteModalOpen(true)}>
          <FormattedMessage id="users.invite" />
        </Button>
      </div>
      {usersData.length === 0 ? (
        <NoContent>
          <FormattedMessage id="users.noUsers" />
        </NoContent>
      ) : (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="users.email" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.name" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.status" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.incorrect_answers" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.declined_answers" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.block" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="users.remove" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map(userData => (
                <UserRow
                  key={userData.user.id}
                  application={application}
                  userData={userData}
                  fetchUsers={() => fetchUsers(currentPage, rowsPerPage)}
                  setIsErrorMessageOpen={setIsErrorMessageOpen}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={count}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchRows={fetchUsers}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
};

export default Users;
