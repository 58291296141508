import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { centToDollars } from '../../../../utils/textUtils';
import styles from './style.module.scss';
import ReplenishBalance from './ReplenishBalance';

export default function Balance({ balance, paymentMethods, setMessage }) {
  const [show, setShow] = useState(false);

  return (
    <h4>
      <Alert variant="info" className={styles.container}>
        <span className={styles.container}>
          <FormattedMessage id="payments.balance" /> {centToDollars(balance)}
        </span>
        <Button variant="outline-light" onClick={() => setShow(true)}>
          <FormattedMessage id="payments.replenish" />
        </Button>
        <ReplenishBalance
          setMessage={setMessage}
          balance={balance}
          paymentMethods={paymentMethods}
          show={show}
          onHide={() => setShow(false)}
        />
      </Alert>
    </h4>
  );
}
