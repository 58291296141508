import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { updateClientPassword } from '../../crud/client.crud';
import CustomSnackbar from '../../ui/CustomSnackbar';

const PasswordUpdate = () => {
  const intl = useIntl();
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const handlePasswordUpdate = async value => {
    const response = await updateClientPassword(value);
    if (response) {
      setIsSuccessMessage(true);
    } else {
      setIsErrorMessage(true);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <CustomSnackbar
        open={isSuccessMessage}
        text={intl.formatMessage({ id: 'client.profile.password.updated' })}
        setOpen={setIsSuccessMessage}
        variant={'success'}
      />
      <CustomSnackbar
        open={isErrorMessage}
        text={intl.formatMessage({ id: 'server.error' })}
        setOpen={setIsErrorMessage}
        variant="error"
      />
      <Formik
        initialValues={{
          newPassword: '',
        }}
        validate={values => {
          const errors = {};

          if (!values.newPassword) {
            errors.newPassword = intl.formatMessage({
              id: 'auth.validation.required_field',
            });
          }

          if (values.newPassword.length < 5) {
            errors.newPassword = intl.formatMessage(
              {
                id: 'form.validation.min_length',
              },
              { min: 5 },
            );
          }

          return errors;
        }}
        onSubmit={values => {
          handlePasswordUpdate(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            className="col-sm-10 col-lg-6"
          >
            <div className="form-group mb-0">
              <TextField
                label="New password"
                type="password"
                margin="normal"
                className="kt-width-full"
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword}
                helperText={touched.newPassword && errors.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
              />
            </div>

            <div className="kt-login__actions text-center">
              <button
                disabled={!values.newPassword || values.newPassword.length < 5}
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                <FormattedMessage id="submit" />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordUpdate;
