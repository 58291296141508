import { combineReducers } from 'redux';

import * as auth from './reducers/authReducer';
import * as applications from './reducers/applicationsReducer';
import { metronic } from '../../_metronic';

export const rootReducer = combineReducers({
  applications: applications.reducer,
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
});
