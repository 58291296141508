import React from 'react';
import ClientUpdate from './ClientUpdate';
import PasswordUpdate from './PasswordUpdate';
import Payment from './Payment/Payment';
import { FullWidthTabs, TabContainer } from '../../ui/FullWidthTabs';
import { useIntl } from 'react-intl';

const ProfileUpdate = () => {
  const intl = useIntl();
  const labels = [
    intl.formatMessage({ id: 'client.profile' }),
    intl.formatMessage({ id: 'client.security' }),
    intl.formatMessage({ id: 'client.payments' }),
  ];

  return (
    <div>
      <FullWidthTabs labels={labels}>
        <TabContainer dir="x">
          <ClientUpdate />
        </TabContainer>
        <TabContainer dir="x">
          <PasswordUpdate />
        </TabContainer>
        <TabContainer dir="x">
          <Payment />
        </TabContainer>
      </FullWidthTabs>
    </div>
  );
};

export default ProfileUpdate;
