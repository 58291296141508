import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Charges from './Charges';
import InvoicePreviewModal from './InvoicePreviewModal';

export default function InvoicePreviewContainer({ invoice }) {
  const [show, setShow] = useState(false);

  return (
    <div className="d-flex justify-content-center">
      <Button onClick={() => setShow(true)}>
        <FormattedMessage id="payments.preview" />
      </Button>
      <InvoicePreviewModal show={show} setShow={setShow} invoice={invoice} />
    </div>
  );
}
