import React from 'react';
import MailSent from './MailSent';
import { useIntl } from 'react-intl';

export function SignUpMailSent() {
  const intl = useIntl();
  return (
    <MailSent
      text={intl.formatMessage({
        id: 'auth.register.success',
      })}
    />
  );
}
