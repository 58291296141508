import axios from 'axios';
axios.defaults.withCredentials = true;
const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;
const PAYMENTS_URL = BASE_URL + '/api/payments';

export async function getClientSecretKey() {
  return await axios.get(`${PAYMENTS_URL}/secret`);
}

export async function createPaymentMethod(paymentMethod) {
  try {
    const response = await axios.post(`${PAYMENTS_URL}/methods`, paymentMethod);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getPaymentMethods() {
  try {
    const response = await axios.get(`${PAYMENTS_URL}/methods`);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function changeDefaultPaymentMethod(id) {
  try {
    const response = await axios.put(`${PAYMENTS_URL}/methods/${id}`);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function deletePaymentMethod(id) {
  try {
    const response = await axios.delete(`${PAYMENTS_URL}/methods/${id}`);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getInvoices(page = 1, limit = 10) {
  const INVOICES_URL = `${PAYMENTS_URL}/invoices?page=${page}&limit=${limit}`;
  try {
    const response = await axios.get(INVOICES_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getPaginatedChargesByInvoice(id, page = 1, limit = 10) {
  try {
    const response = await axios.get(
      `${PAYMENTS_URL}/${id}/charges?page=${page}&limit=${limit}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getApplicationsStats(id) {
  try {
    const response = await axios.get(`${PAYMENTS_URL}/${id}/total`);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function createPaymentIntent(amount) {
  try {
    const response = await axios.post(`${PAYMENTS_URL}/replenish`, {
      amount,
    });
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getPaginatedPayments(page = 1, limit = 10) {
  try {
    const response = await axios.get(
      `${PAYMENTS_URL}/payments-list?page=${page}&limit=${limit}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getPaginatedCharges(page = 1, limit = 10) {
  try {
    const response = await axios.get(
      `${PAYMENTS_URL}/charges-list?page=${page}&limit=${limit}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}
