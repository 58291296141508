import axios from 'axios';
axios.defaults.withCredentials = true;
const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;
const CLIENT_URL = BASE_URL + '/api/client';

export async function updateClientInfo(values) {
  const UPDATE_URL = `${CLIENT_URL}/profile`;
  try {
    const response = await axios.put(UPDATE_URL, {
      fullName: values.fullName,
      email: values.email,
    });
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function updateClientPassword(values) {
  const PASSWORD_URL = `${CLIENT_URL}/profile/password`;
  try {
    const response = await axios.put(PASSWORD_URL, {
      newPassword: values.newPassword,
    });
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}
