import React, { useEffect, useState } from 'react';
import classes from './Applications.module.scss';
import {
  getApplications,
  createApplication,
} from '../../crud/application.crud';
import AppModalForm from './ModalForms/AppModalForm';
import { useHistory } from 'react-router-dom';
import MediaCard from './applicationPreview/MediaCard';
import { BigSpinner } from '../../ui/BigSpinner';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../partials/content/Portlet';
import { Button } from 'react-bootstrap';
import CustomSnackbar from '../../ui/CustomSnackbar';
import { connect } from 'react-redux';
import { actions } from '../../store/reducers/applicationsReducer';
import { SELF } from '../../constants/AppConstants';
import { CustomizedSnackbars } from '../../ui/SnackBar';

function Applications({
  isApplicationDeletedMessage,
  setIsApplicationDeletedMessage,
}) {
  const intl = useIntl();
  const [apps, setApps] = useState(null);
  const [isCreateWindowOpen, setIsCreateWindowOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);

  const CreateWindowlink = () => (
    <strong
      onClick={() => setIsCreateWindowOpen(true)}
      className={classes['text-hover']}
    >
      <FormattedMessage id="here" />
    </strong>
  );

  let history = useHistory();

  const getApps = async () => {
    setIsLoading(true);
    const applications = await getApplications();

    if (applications) {
      setApps(applications);
    } else {
      setIsErrorMessageOpen(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getApps();
  }, []);

  const goToNewApp = newAppId => {
    history.push(`/applications/${newAppId}`);
  };

  const createNewApp = async values => {
    const response = await createApplication(values);

    if (response) {
      await getApps();
      goToNewApp(response.data.id);

      return true;
    } else {
      return false;
    }
  };

  return (
    <Portlet>
      <PortletHeader
        title={intl.formatMessage({ id: 'applications' })}
        toolbar={
          <div className="d-flex justify-content-end align-items-center">
            <Button
              style={{ backgroundColor: '#208838', border: 0 }}
              className="btn"
              onClick={() => setIsCreateWindowOpen(true)}
            >
              <FormattedMessage id="applications.create.title" />
            </Button>
          </div>
        }
      />
      <PortletBody>
        <CustomizedSnackbars
          open={isErrorMessageOpen}
          setOpen={setIsErrorMessageOpen}
          message={intl.formatMessage({ id: 'server.error' })}
          variant="error"
          verticalAlign="top"
        />
        <CustomizedSnackbars
          open={isApplicationDeletedMessage}
          setOpen={setIsApplicationDeletedMessage}
          message={intl.formatMessage({ id: 'application.delete' })}
          variant="success"
          verticalAlign="top"
        />
        <div>
          <AppModalForm
            open={isCreateWindowOpen}
            handleClose={() => setIsCreateWindowOpen(false)}
            initialValues={{
              name: '',
              description: '',
              pricingModel: SELF,
            }}
            handleSubmit={createNewApp}
            dialogTitle={intl.formatMessage({
              id: 'applications.create.title',
            })}
            descriptionText={intl.formatMessage({
              id: 'applications.create.description',
            })}
            failStatus={intl.formatMessage({
              id: 'application.create.name_exist',
            })}
          />
          {isLoading && (
            <div className="d-flex justify-content-center">
              <BigSpinner />
            </div>
          )}
          {apps && apps.length === 0 && (
            <div className="text-center">
              {intl.formatMessage(
                { id: 'applications.no_applications' },
                {
                  link: <CreateWindowlink />,
                },
              )}
            </div>
          )}
          {apps && apps.length > 0 && (
            <div className={classes['grid-col-3']}>
              {apps.map(app => (
                <MediaCard key={app.id} app={app} />
              ))}
            </div>
          )}
        </div>
      </PortletBody>
    </Portlet>
  );
}

const mapStateToProps = state => {
  return {
    isApplicationDeletedMessage: state.applications.isApplicationDeletedMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsApplicationDeletedMessage: bool => {
      dispatch(actions.setIsApplicationDeletedMessage(bool));
    },
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Applications),
);
