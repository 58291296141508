import React, { useRef, useState } from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';

const TooltipButton = ({ onClick, children, tooltipText, ...props }) => {
  const copyButtonRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleClick = () => {
    onClick();
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 4000);
  };
  return (
    <>
      <Button ref={copyButtonRef} {...props} onClick={handleClick}>
        {children}
      </Button>
      <Overlay target={copyButtonRef} show={show} placement="right">
        {props => (
          <Tooltip id="overlay-example" {...props}>
            {tooltipText}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default TooltipButton;
