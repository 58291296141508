import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Builder from './Builder';
import Dashboard from './Dashboard';
import DocsPage from './docs/DocsPage';
import { LayoutSplashScreen } from '../../../_metronic';
import Applications from '../applications/Applications';
import { Profile } from '../profile/Profile';
import Application from '../applications/application/Application';

const GoogleMaterialPage = lazy(() =>
  import('./google-material/GoogleMaterialPage'),
);
const ReactBootstrapPage = lazy(() =>
  import('./react-bootstrap/ReactBootstrapPage'),
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/applications" />
        }
        <Route exact path="/applications" component={Applications} />
        <Route path="/applications/:id" component={Application} />
        <Route path="/profile" component={Profile} />
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
