import React, { useState, useEffect } from 'react';
import * as paymentService from '../../../../crud/payment.crud';
import InvoicesTable from './InvoicesTable';
import { useIntl } from 'react-intl';

export default function Invoices({ setMessage }) {
  const intl = useIntl();
  const [invoices, setInvoices] = useState([]);
  const [count, setCount] = useState(0);

  const getInvoices = async (pageNumber, limit) => {
    const invoicesAndCount = await paymentService.getInvoices(
      pageNumber,
      limit,
    );

    if (invoicesAndCount) {
      const [invoices, count] = invoicesAndCount;
      setInvoices(invoices);
      setCount(count);
    } else {
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  useEffect(() => {
    getInvoices(1);
  }, []);

  return (
    <div className="w-100">
      <InvoicesTable
        invoices={invoices}
        count={count}
        fetchRows={getInvoices}
      />
    </div>
  );
}
