import React from 'react';
import { Link } from 'react-router-dom';
import classes from './MailSent.module.scss';
import { FormattedMessage } from 'react-intl';

export default ({ text }) => {
  return (
    <div className={classes['wrapper-container']}>
      <section className={classes['inner-container']}>
        <h3 className="text-center">
          <FormattedMessage id="auth.congratulations" />
        </h3>
        <div className={classes['main']}>
          <article className="text-center fon">{text}</article>
          <Link to="/auth/login" className="text-center">
            <button className="btn btn-primary button-big mt-2">
              <FormattedMessage id="auth.toLogin" />
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};
