import React, { useEffect } from 'react';
import classes from './CustomSnackbar.module.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const CustomSnackbar = ({ open, setOpen, variant, text }) => {
  const Icon = variantIcon[variant];

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
  }, [open]);
  return (
    open && (
      <div className={[classes['snackbar'], classes[variant]].join(' ')}>
        <div className={classes['message']}>
          <Icon />
          <span className={classes['message-text']}>{text}</span>
        </div>
        <div className={classes['closeButton']} onClick={() => setOpen(false)}>
          <CloseIcon className={classes['icon']} />
        </div>
      </div>
    )
  );
};

export default CustomSnackbar;
