import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField, FormGroup } from '@material-ui/core';
import { declineTaskLog } from '../../../../../crud/application.crud';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../../../store/reducers/applicationsReducer';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '80%',
    top: '30%',
    left: '10%',
    maxHeight: '500px',
    overflowY: 'scroll',
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  selectForm: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    display: 'flex',
    flex: 1,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const TaskLogDeclineModal = ({ log }) => {
  const intl = useIntl();
  const { application } = useSelector(({ applications }) => applications);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const isLogOld =
    new Date(log.created) < new Date(Date.now() - 1000 * 60 * 60 * 24 * 5);
  const dispatch = useDispatch();

  const handleChange = e => {
    setReason(e.target.value);
    setIsOtherReason(e.target.value === intl.formatMessage({ id: 'tasks.log.decline.other' }));
  };

  const handleSubmit = async () => {
    const declineReason = isOtherReason ? otherReason : reason;
    const response = await declineTaskLog(application.id, log.id, declineReason);

    if (response) {
      dispatch(actions.fetchTasks(application.id));
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        variant="danger"
        disabled={log.isDeclined || isLogOld || log.isPaid}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="tasks.log.decline" />
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.paper}>
          <h4 className={classes.header}>
            <FormattedMessage id="tasks.log.decline.header" />
          </h4>

          <form autoComplete="off">
            <FormGroup className={classes.root}>
              <FormControl className={classes.selectForm}>
                <InputLabel htmlFor="age-simple">
                  <FormattedMessage id="tasks.log.decline.reason" />
                </InputLabel>
                <Select
                  value={reason}
                  onChange={handleChange}
                  inputProps={{
                    id: 'age-simple',
                  }}
                >
                  <MenuItem
                    value={intl.formatMessage({
                      id: 'tasks.log.decline.incorrect',
                    })}
                  >
                    <FormattedMessage id="tasks.log.decline.incorrect" />
                  </MenuItem>
                  <MenuItem 
                    value={intl.formatMessage({
                      id: 'tasks.log.decline.other',
                    })}
                  >
                    <FormattedMessage id="tasks.log.decline.other" />
                  </MenuItem>
                </Select>
              </FormControl>
              {isOtherReason && (
                <div className={classes.textField}>
                  <TextField
                    id="standard-name"
                    label="Your reason"
                    value={otherReason}
                    onChange={e => setOtherReason(e.target.value)}
                    margin="normal"
                    autoFocus
                  />
                </div>
              )}
            </FormGroup>
            <div className={classes.buttons}>
              <Button
                onClick={() => setOpen(false)}
                style={{ display: 'flex', alignSelf: 'center' }}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <Button
                disabled={isOtherReason ? !otherReason : !reason}
                onClick={handleSubmit}
                style={{ display: 'flex', alignSelf: 'center' }}
              >
                <FormattedMessage id="submit" />
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
