import React, { useEffect, useState } from 'react';
import './Print.scss';
import { Button } from 'react-bootstrap';
import { getApplicationsStats } from '../../../../../crud/payment.crud';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { tableStyles } from '../../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import { Paper } from '@material-ui/core';
import MonthDataTable from './MonthDataTable';
import Total from './Total';
import InvoiceData from './InvoiceData';

export default function PrintCharges({ invoice, setIsPrintMode }) {
  const intl = useIntl();
  const [rows, setRows] = useState([]);
  const classes = tableStyles();
  const [total, setTotal] = useState({
    enrollments: 0,
    refunds: 0,
    totalBalance: 0,
  });

  const user = useSelector(state => state.auth.user);

  const setApplicationsRows = async () => {
    const [rows, totalData] = await getApplicationsStats(invoice.id);
    setTotal(totalData);
    setRows(Object.values(rows));
    window.print();
  };

  useEffect(() => {
    setApplicationsRows();
  }, []);

  return (
    <Paper className={classes.root}>
      <div className="d-flex justify-content-end p-2">
        <Button
          size="sm"
          variant="primary"
          onClick={() => setIsPrintMode(false)}
        >
          {<FormattedMessage id="back" />}
        </Button>
      </div>
      <div className="print-container">
        <img
          src="/media/logos/mitm_logo_dark.png"
          alt="Logo"
          className="logo-image"
        />
        <h3 className="invoice-header uppercase">
          {`${intl.formatMessage({ id: 'payments.invoice' })} #${invoice.id}`}
        </h3>
        <InvoiceData user={user} invoice={invoice} />
        <section className="d-flex flex-column">
          <MonthDataTable rows={rows} />
          <Total total={total} />
        </section>
      </div>
    </Paper>
  );
}
