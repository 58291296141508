import React from 'react';
import { useParams } from 'react-router';

import { Portlet, PortletBody } from '../../../partials/content/Portlet';
import { Alert } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApplication } from './useApplication';
import Tabs from './Tabs';
import ApplicationHeader from './ApplicationHeader';
import { CustomizedSnackbars } from '../../../ui/SnackBar';

function Application() {
  const { id } = useParams();
  const intl = useIntl();
  const {
    application,
    setApplication,
    isErrorMessageOpen,
    setIsErrorMessageOpen,
    isSettingsChanged,
    setIsSettingsChanged,
    updateApp,
    deleteApp,
    reload,
    onAlertClose,
    fetchApplication,
    isWebhookError,
  } = useApplication(id);

  if (!application) {
    return null;
  }

  return (
    <div className="col-xl-12">
      <CustomizedSnackbars
        open={isErrorMessageOpen}
        setOpen={setIsErrorMessageOpen}
        message={intl.formatMessage({ id: 'server.error' })}
        variant="error"
        verticalAlign="top"
      />
      <Portlet fluidHeight={true}>
        <ApplicationHeader
          application={application}
          isErrorMessageOpen={isErrorMessageOpen}
          setIsErrorMessageOpen={setIsErrorMessageOpen}
          updateApp={updateApp}
          deleteApp={deleteApp}
          reload={reload}
        />
        <PortletBody>
          <Alert
            show={isWebhookError}
            variant="danger"
            dismissible={true}
            onClose={() => onAlertClose()}
          >
            <FormattedMessage id="application.code_error" />
          </Alert>
          <Alert
            show={isSettingsChanged}
            className="text-white"
            variant="warning"
            dismissible={true}
            onClose={() => setIsSettingsChanged(false)}
          >
            <FormattedMessage id="settings.unsaved" />
          </Alert>
          <Tabs
            application={application}
            setApplication={setApplication}
            fetchApplication={fetchApplication}
            setIsErrorMessageOpen={setIsErrorMessageOpen}
          />
        </PortletBody>
      </Portlet>
    </div>
  );
}

export default Application;
