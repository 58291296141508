import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableHead } from '@material-ui/core';
import TaskLogModal from './TaskLogModal';
import Pagination from './Pagination';

export const tableStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'scroll',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function TasksPaginatedTable({
  tasks,
  count,
  fetchRows,
  children,
  setIsErrorMessageOpen,
}) {
  const classes = tableStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="tasks.type" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="tasks.uid" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="tasks.created" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="tasks.status" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="tasks.response.code" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="tasks.logs" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) =>
              index < rowsPerPage ? (
                <TableRow key={task.id}>
                  <TableCell component="th" scope="row">
                    {task.type}
                  </TableCell>
                  <TableCell align="right">{task.uid}</TableCell>
                  <TableCell align="right">
                    {new Date(task.created).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{task.status}</TableCell>
                  <TableCell align="right">{task.responseCode}</TableCell>
                  <TableCell align="center">
                    <TaskLogModal
                      task={task}
                      setIsErrorMessageOpen={setIsErrorMessageOpen}
                    />
                  </TableCell>
                </TableRow>
              ) : null,
            )}
          </TableBody>
        </Table>
        <Pagination
          count={count}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fetchRows={fetchRows}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </Paper>
  );
}
