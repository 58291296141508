import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TableHead } from '@material-ui/core';
import Pagination from '../../../applications/application/taskList/TaskTable/Pagination';
import InvoicePreviewContainer from './InvoicePreviewContainer';
import { tableStyles } from '../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import { getInterval } from '../../../../utils/textUtils';
import { noContentStyle } from '../../../applications/application/taskList/TaskList';
import { Badge } from 'react-bootstrap';
import NoContent from '../../../../ui/NoContent';

export default function InvoicesTable({
  invoices,
  count,
  fetchRows,
  children,
}) {
  const tableClasses = tableStyles();
  const noContentClasses = noContentStyle();
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  if (!count) {
    return (
      <NoContent>
        <FormattedMessage id="payments.no_invoices" />
      </NoContent>
    );
  }
  return (
    <Paper>
      <div className={tableClasses.tableWrapper}>
        <Table className={tableClasses.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="payments.period" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="payments.created" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="payments.application_name" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="payments.isPaid" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="payments.invoice_id" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="payments.preview" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice, index) =>
              index < rowsPerPage ? (
                <TableRow key={invoice.id}>
                  <TableCell component="th" scope="row">
                    {getInterval(invoice.startDate, invoice.endDate)}
                  </TableCell>
                  <TableCell align="right">
                    {new Date(invoice.created).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    {invoice.application.name}
                  </TableCell>
                  <TableCell align="right">
                    <Badge
                      className="text-white"
                      variant={invoice.isPaid ? 'success' : 'warning'}
                    >
                      <FormattedMessage id={invoice.isPaid ? 'yes' : 'no'} />{' '}
                    </Badge>
                  </TableCell>
                  <TableCell align="right">{invoice.id}</TableCell>
                  <TableCell align="center">
                    <InvoicePreviewContainer invoice={invoice} />
                  </TableCell>
                </TableRow>
              ) : null,
            )}
          </TableBody>
        </Table>
        <Pagination
          count={count}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fetchRows={fetchRows}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </Paper>
  );
}
