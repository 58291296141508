import React, { useState } from 'react';
import * as paymentService from '../../../../crud/payment.crud';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import SpinnerButton from '../../../../ui/SpinnerButton';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from './CardElementOptions';
import { useSelector } from 'react-redux';

export default function AddCard({ setMessage, refreshMethods, isPrimary }) {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const [isCardEdit, setIsCardEdit] = useState(false);

  const { user } = useSelector(state => state.auth);

  const getClientSecret = async () => {
    try {
      const { data } = await paymentService.getClientSecretKey();

      setClientSecret(data.client_secret);
      setIsCardEdit(true);
    } catch (e) {
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  const sendMethodToServer = async stripeMethod => {
    const payment = {
      stripeToken: stripeMethod,
      isPrimary,
      type: 'stripe',
    };

    try {
      await paymentService.createPaymentMethod(payment);
      await refreshMethods();
      setMessage({
        open: true,
        variant: 'success',
        text: intl.formatMessage({ id: 'payments.card_added' }),
      });
    } catch (e) {
      console.log(e);
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  const createPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.fullName,
        },
      },
    });

    if (result.error) {
      setMessage({
        open: true,
        variant: 'error',
        text: result.error.message,
      });
    } else {
      await sendMethodToServer(result.setupIntent.payment_method);

      //TODO: handle case when stripe has created the payment method but our server is not responding
    }
    setIsCardEdit(false);
  };

  return (
    <div className="mt-5 d-flex flex-column p-3">
      {!isCardEdit && (
        <SpinnerButton
          variant="primary"
          style={{ margin: '10px', alignSelf: 'center' }}
          onClick={getClientSecret}
        >
          <FormattedMessage id="payments.add_card" />
        </SpinnerButton>
      )}

      {isCardEdit && (
        <div>
          <label>
            <FormattedMessage id="payments.card_details" />
          </label>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <div className="d-flex justify-content-between">
            <SpinnerButton
              variant="info"
              disabled={!stripe || !clientSecret}
              style={{ margin: '10px' }}
              onClick={async () => {
                await createPaymentMethod();
                setClientSecret(null);
              }}
            >
              <FormattedMessage id="payments.save_card" />
            </SpinnerButton>

            <Button
              style={{ margin: '10px' }}
              variant="info"
              onClick={() => {
                setIsCardEdit(false);
                setClientSecret(null);
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
