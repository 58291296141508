import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './PaymentMethods/CardSectionStyles.scss';
import * as paymentService from '../../../crud/payment.crud';
import Balance from './Balance/index';
import CustomSnackbar from '../../../ui/CustomSnackbar';
import { useStripe } from '@stripe/react-stripe-js';
import Menu from './Menu';

const Payment = () => {
  const stripe = useStripe();

  const { user } = useSelector(state => state.auth);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [message, setMessage] = useState({
    open: false,
    variant: 'success',
    text: '',
  });

  const refreshMethods = async () => {
    const methods = await paymentService.getPaymentMethods();

    if (methods) {
      setPaymentMethods(methods);
    }
  };

  useEffect(() => {
    refreshMethods();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      <CustomSnackbar
        open={message.open}
        text={message.text}
        setOpen={open =>
          setMessage({
            open: open,
            variant: 'success',
            text: '',
          })
        }
        variant={message.variant}
      />
      <div className="w-100">
        <Balance
          balance={user.balance}
          paymentMethods={paymentMethods}
          setMessage={setMessage}
        />
        <Menu
          setMessage={setMessage}
          paymentMethods={paymentMethods}
          refreshMethods={refreshMethods}
          setMessage={setMessage}
          isPrimary={paymentMethods && paymentMethods.length === 0}
        />
      </div>
    </div>
  );
};

export default Payment;
