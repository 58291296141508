import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  onlineBadge: { color: 'white', width: '5rem' },
  offlineBadge: { width: '5rem' },
  blockedBadge: { width: '5rem', color: 'white' },
  busyBadge: {
    width: '5rem',
    marginTop: '1rem',
    backgroundColor: 'rgb(89, 89, 89)',
    color: 'white',
  },
}));

const UserStatus = ({ isUserBlocked, connections }) => {
  const classes = useStyles();

  if (isUserBlocked) {
    return (
      <span className={'badge badge-danger ' + classes.blockedBadge}>
        <FormattedMessage id="users.blocked" />
      </span>
    );
  } else if (connections.length > 0) {
    return (
      <>
        <div>
          <span className={'badge badge-success ' + classes.onlineBadge}>
            <FormattedMessage id="users.online" />
          </span>
        </div>
        {connections[0].isBusy && (
          <div>
            <span className={'badge ' + classes.busyBadge}>
              <FormattedMessage id="users.busy" />
            </span>
          </div>
        )}
      </>
    );
  } else if (connections.length === 0) {
    return (
      <span className={'badge badge-secondary ' + classes.offlineBadge}>
        <FormattedMessage id="users.offline" />
      </span>
    );
  }
};

export default UserStatus;
