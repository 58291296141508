import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTimePast } from '../../../../../utils/textUtils';
import { TaskLogDeclineModal } from './TaskLogDeclineModal';

const TaskLogRow = ({ log }) => {
  const intl = useIntl();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {new Date(log.created).toLocaleString()}
      </TableCell>
      <TableCell align="right">
        <span className={!log.isCorrect ? 'text-danger' : undefined}>
          {log.answer}
        </span>
      </TableCell>
      <TableCell align="right">
        <FormattedMessage id={log.isTimeout ? 'yes' : 'no'} />
      </TableCell>
      <TableCell align="right">
        <FormattedMessage id={log.isSkipped ? 'yes' : 'no'} />
      </TableCell>
      <TableCell align="right">
        {log.isDeclined ? (
          <div>
            <FormattedMessage id="tasks.log.declined" />: {log.declineReason}
          </div>
        ) : (
          <FormattedMessage id="no" />
        )}
      </TableCell>
      <TableCell align="right">
        {log.timeToAnswer && getTimePast(log.timeToAnswer)}
      </TableCell>
      <TableCell align="right">
        {log.user
          ? `${log.user.firstName} ${log.user.lastName}`
          : intl.formatMessage({ id: 'none' })}
      </TableCell>
      <TableCell align="right">
        <TaskLogDeclineModal log={log} />
      </TableCell>
    </TableRow>
  );
};

export default TaskLogRow;
