import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Card({ method, onClick, children }) {
  return (
    <div className="CardPreview" onClick={onClick}>
      <div className="d-flex justify-content-start">
        <div className="card-badge badge-info">
          {method.brand.toUpperCase()}
        </div>
        {method.isPrimary && (
          <div className="card-badge badge-success ml-2">
            <FormattedMessage id="payments.card_primary" />
          </div>
        )}
        {children}
      </div>
      <div className="card-number">{`**** **** **** ${method.last4}`}</div>
    </div>
  );
}
