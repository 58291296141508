import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { checkUser } from '../../crud/auth.crud';
import * as routerHelpers from '../../router/RouterHelpers';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  Unregister: '[Unregister] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: 'SetUser',
};

const initialAuthState = {
  user: null,
  isRegistered: false,
};

export const reducer = persistReducer(
  { storage, key: 'demo4-auth', whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { user: action.payload };
      }

      case actionTypes.Register: {
        return { user: null, isRegistered: true };
      }

      case actionTypes.Unregister: {
        return initialAuthState;
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        return state;
      }

      case actionTypes.SetUser: {
        return { ...state, user: action.payload };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  login: client => ({ type: actionTypes.Login, payload: client }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  setUser: user => ({ type: actionTypes.SetUser, payload: user }),
  unRegister: () => ({ type: actionTypes.Unregister }),
  logout: () => ({ type: actionTypes.Logout }),
};
