import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import * as auth from '../../store/reducers/authReducer';
import { register } from '../../crud/auth.crud';

function Registration(props) {
  const { intl } = props;

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="auth.register.title" />
          </h3>
        </div>

        <Formik
          initialValues={{
            email: '',
            fullName: '',
            password: '',
            confirmPassword: '',
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: 'auth.validation.required_field',
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: 'auth.validation.invalid_field',
              });
            }

            if (!values.fullName) {
              errors.fullName = intl.formatMessage({
                id: 'auth.validation.required_field',
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: 'auth.validation.required_field',
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: 'auth.validation.required_field',
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            register(values.email, values.fullName, values.password)
              .then(data => {
                props.register();
              })
              .catch(err => {
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: 'auth.validation.invalid_login',
                  }),
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label={intl.formatMessage({
                    id: 'auth.input.fullname',
                  })}
                  className="kt-width-full"
                  name="fullName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  helperText={touched.fullName && errors.fullName}
                  error={Boolean(touched.fullName && errors.fullName)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  label={intl.formatMessage({
                    id: 'auth.input.email',
                  })}
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label={intl.formatMessage({
                    id: 'auth.input.password',
                  })}
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label={intl.formatMessage({
                    id: 'auth.input.confirm_password',
                  })}
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword,
                  )}
                />
              </div>

              <div className="kt-login__actions">
                <Link to="/auth">
                  <button
                    type="button"
                    className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                  >
                    <FormattedMessage id="back" />
                  </button>
                </Link>

                <button
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  <FormattedMessage id="submit" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
