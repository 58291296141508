import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';

import { tableStyles } from '../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import { getInterval } from '../../../../utils/textUtils';
import { Button } from 'react-bootstrap';
import ChargesTable from './ChargesTable';

export default function Charges({
  charges,
  count,
  fetchRows,
  children,
  setIsPrintMode,
  invoice,
}) {
  const classes = tableStyles();

  return (
    <Paper className={classes.root}>
      <div className="d-flex p-3 justify-content-between align-center">
        <h4>
          <FormattedMessage
            id="payments.invoice_for"
            values={{ id: invoice.id }}
          />{' '}
          {getInterval(invoice.startDate, invoice.endDate)}
        </h4>
        <Button
          size="sm"
          variant="primary"
          onClick={() => setIsPrintMode(true)}
        >
          {<FormattedMessage id="payments.print" />}
        </Button>
      </div>
      <ChargesTable charges={charges} count={count} fetchRows={fetchRows} />
    </Paper>
  );
}
