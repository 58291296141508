import React from 'react';
import classes from './BigSpinner.module.scss';
export const BigSpinner = () => {
  return (
    <div className={classes['lds-ring']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
