/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { FormattedMessage, useIntl } from 'react-intl';
import * as auth from '../../store/reducers/authReducer';
import { userLogout } from '../../crud/auth.crud';
import CustomSnackbar from '../../ui/CustomSnackbar';

function UserProfile({ user, showHi, showAvatar, showBadge, logout }) {
  const [isError, setIsError] = useState(false);
  const intl = useIntl();

  const logoutFromApp = async () => {
    const response = await userLogout();
    if (response) {
      logout();
    } else {
      setIsError(true);
    }
  };

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <CustomSnackbar
        open={isError}
        setOpen={setIsError}
        variant="error"
        text={intl.formatMessage({ id: 'server.error' })}
      />
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div
          className="kt-header__topbar-wrapper"
          data-toggle="dropdown"
          data-offset="10px,0px"
        >
          {showHi && (
            <span className="kt-header__topbar-welcome kt-hidden-mobile">
              Hi,
            </span>
          )}

          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile text-nowrap">
              {user.fullName}
            </span>
          )}

          {showAvatar && <img alt="Pic" src={user.pic} />}

          {showBadge && (
            <span className="kt-header__topbar-icon">
              {/* TODO: Should get from currentUser */}
              <b>{user && user.fullName[0]}</b>
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
          }}
        >
          <div className="kt-user-card__avatar">
            <img alt="Pic" className="kt-hidden" src={user.pic} />
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              {user && user.fullName[0]}
            </span>
          </div>
          <div className="kt-user-card__name">{user.fullName}</div>
          <div className="kt-user-card__badge">
            <button
              onClick={logoutFromApp}
              className="btn btn-success btn-sm btn-bold btn-font-md"
            >
              <FormattedMessage id="auth.signout.button" />
            </button>
          </div>
        </div>
        {/* <div className="kt-notification">
            <Link to="/profile" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>

            <Link to="/applications" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Applications
                </div>
                <div className="kt-notification__item-time">
                  Manage your applications
                </div>
              </div>
            </Link>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Messages
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </a>
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = state => ({ user: state.auth.user });
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(auth.actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
