import React, { useRef } from 'react';
import SettingsLine from './SettingsLine';
import { useIntl } from 'react-intl';

const AllSettingsLines = ({ application, setApplication }) => {
  const intl = useIntl();

  const webhookRef = useRef(null);
  const accessTokenRef = useRef(null);
  return (
    <div>
      <SettingsLine id="published">
        <input
          type="checkbox"
          id="published"
          checked={application.published}
          onChange={e =>
            setApplication({ ...application, published: e.target.checked })
          }
        />
      </SettingsLine>
      <SettingsLine id="webhook">
        <input
          ref={webhookRef}
          type="text"
          id="webhook"
          value={application.webhook}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              webhook: e.target.value.trim() || null,
            })
          }
        />
      </SettingsLine>
      <SettingsLine
        id="accessToken"
        translationValues={{ token: <code>X-Auth-Token: &lt;token&gt;</code> }}
      >
        <input
          ref={accessTokenRef}
          type="text"
          id="accessToken"
          value={application.accessToken}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              accessToken: e.target.value.trim() || null,
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="timeout">
        <input
          type="text"
          id="timeout"
          value={application.timeout}
          className="form-control"
          onChange={e => {
            setApplication({
              ...application,
              timeout: Number(e.target.value.split(/\D/).join('')),
            });
          }}
        />
      </SettingsLine>
      <SettingsLine id="maxAttempts">
        <input
          type="text"
          id="maxAttempts"
          value={application.maxAttempts}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              maxAttempts: Number(e.target.value.split(/\D/).join('')),
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="maxTimeouts">
        <input
          type="text"
          id="maxTimeouts"
          value={application.maxTimeouts}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              maxTimeouts: Number(e.target.value.split(/\D/).join('')),
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="maxSkips">
        <input
          type="text"
          id="maxSkips"
          value={application.maxSkips}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              maxSkips: Number(e.target.value.split(/\D/).join('')),
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="answersNumber">
        <input
          type="text"
          id="answersNumber"
          value={application.answersNumber}
          className="form-control"
          onChange={e =>
            setApplication({
              ...application,
              answersNumber: Number(e.target.value.split(/\D/).join('')),
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="shouldWaitForAvailableUsers">
        <input
          type="checkbox"
          id="shouldWaitForAvailableUsers"
          checked={application.shouldWaitForAvailableUsers}
          onChange={e =>
            setApplication({
              ...application,
              shouldWaitForAvailableUsers: e.target.checked,
            })
          }
        />
      </SettingsLine>
      <SettingsLine id="shouldWaitForAvailableBusyUsers">
        <input
          type="checkbox"
          id="shouldWaitForAvailableBusyUsers"
          checked={application.shouldWaitForAvailableBusyUsers}
          onChange={e =>
            setApplication({
              ...application,
              shouldWaitForAvailableBusyUsers: e.target.checked,
            })
          }
        />
      </SettingsLine>
      {application.pricingModel === 'cloud' && (
        <SettingsLine id="price">
          <input
            type="number"
            id="price"
            value={application.price}
            className="form-control"
            onChange={e => {
              setApplication({ ...application, price: e.target.value });
            }}
          />
        </SettingsLine>
      )}
    </div>
  );
};

export default AllSettingsLines;
