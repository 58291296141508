import {
  removeUserFromApplication,
  blockUserFromApplication,
  unblockUserFromApplication,
} from '../../../../crud/application.crud';

export const useUsers = (fetchUsers, setIsErrorMessageOpen) => {
  const checkResponse = response => {
    if (response) {
      fetchUsers();
    } else {
      setIsErrorMessageOpen(true);
    }
  };

  const removeUser = async (applicationId, userId) => {
    const response = await removeUserFromApplication(applicationId, userId);
    checkResponse(response);
  };

  const blockUser = async (applicationId, userId) => {
    const response = await blockUserFromApplication(applicationId, userId);
    checkResponse(response);
  };

  const unblockUser = async (applicationId, userId) => {
    const response = await unblockUserFromApplication(applicationId, userId);
    checkResponse(response);
  };

  return { removeUser, blockUser, unblockUser };
};
