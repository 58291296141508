import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { centToDollars } from '../../../../../utils/textUtils';

export default function MonthDataTable({ rows }) {
  return (
    <Table striped bordered size="sm">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="application" />
          </th>
          <th>
            <FormattedMessage id="payments.cost_per_month" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr key={row.name}>
            <td>{row.name}</td>
            <td>{centToDollars(row.cost)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
