import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { updateClientInfo } from '../../crud/client.crud';
import { actions } from '../../store/reducers/authReducer';
import CustomSnackbar from '../../ui/CustomSnackbar';

const ClientUpdate = ({ user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isMessage, setIsMessage] = useState(false);
  const [message, setMessage] = useState({
    variant: 'success',
    text: <FormattedMessage id="client.profile.name.updated" />,
  });

  const handleClientUpdate = async values => {
    const user = await updateClientInfo(values);

    if (user) {
      setMessage({
        ...message,
        text:
          values.email !== user.email ? (
            <div>
              <p>
                <FormattedMessage id="client.profile.name.updated" />
              </p>
              <p>
                <FormattedMessage id="client.profile.mail_sent" />
              </p>
            </div>
          ) : (
            message.text
          ),
      });

      setIsMessage(true);
    } else {
      setMessage({
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });

      setIsMessage(true);
    }
    dispatch(actions.setUser(user));
  };
  return (
    <div className="d-flex flex-column align-items-center">
      <CustomSnackbar
        open={isMessage}
        text={message.text}
        setOpen={setIsMessage}
        variant={message.variant}
      />
      <Formik
        initialValues={{
          fullName: user.fullName,
          email: user.email,
        }}
        validate={values => {
          const errors = {};

          if (!values.fullName) {
            errors.fullName = intl.formatMessage({
              id: 'auth.validation.required_field',
            });
          }

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: 'auth.validation.required_field',
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: 'auth.validation.invalid_field',
            });
          }

          return errors;
        }}
        onSubmit={async values => {
          await handleClientUpdate(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            className="col-lg-6 col-sm-10"
          >
            <div className="form-group mb-0">
              <TextField
                margin="normal"
                label={intl.formatMessage({ id: 'fullName' })}
                className="kt-width-full"
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                helperText={touched.fullName && errors.fullName}
                error={Boolean(touched.fullName && errors.fullName)}
              />
            </div>

            <div className="form-group mb-0">
              <TextField
                label="Email"
                margin="normal"
                className="kt-width-full"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                disabled={
                  user.fullName === values.fullName &&
                  user.email === values.email
                }
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                <FormattedMessage id="submit" />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(ClientUpdate);
