import React from 'react';
import PaymentCard from './PaymentCard';
import { FormattedMessage } from 'react-intl';
import { noContentStyle } from '../../../applications/application/taskList/TaskList';

export default function AllPaymentCards({
  paymentMethods,
  refreshMethods,
  setMessage,
}) {
  const noMethodsClasses = noContentStyle();

  return (
    <div className="mt-5 d-flex flex-column align-items-center">
      <h4>
        <FormattedMessage id="payments.methods" />
      </h4>
      {paymentMethods.length > 0 ? (
        paymentMethods.map((method, idx) => (
          <PaymentCard
            key={method.id}
            method={method}
            refreshMethods={refreshMethods}
            setMessage={setMessage}
          />
        ))
      ) : (
        <div className={`${noMethodsClasses.main} mt-5`}>
          <div style={{ textTransform: 'uppercase', fontSize: '2rem' }}>
            <FormattedMessage id="payments.no_methods" />
          </div>
        </div>
      )}
    </div>
  );
}
