import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 140,
  },
});

export default function MediaCard({ app }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {app.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {app.description}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          justifyContent: 'space-between',
          marginRight: '10px',
          padding: '1rem',
          alignItems: 'center',
        }}
      >
        {app.hasNewErrors && (
          <div className="badge badge-danger">
            <FormattedMessage id="error" />
          </div>
        )}
        <Link to={`/applications/${app.id}`}>
          <Button size="sm" color="primary">
            <FormattedMessage id="open" />
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
