import React from 'react';
import {
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';
import { CustomizedSnackbars } from '../../../ui/SnackBar';
import { useIntl } from 'react-intl';
import { DropdownMenu } from '../../../ui/DropDownMenu';
import { DropDownMenuItems } from './DropDownMenuItems/DropDownMenuItems';

export default function ApplicationHeader({
  application,
  isErrorMessageOpen,
  setIsErrorMessageOpen,
  updateApp,
  deleteApp,
  reload,
}) {
  const intl = useIntl();
  return (
    <PortletHeader
      title={application.name}
      toolbar={
        <PortletHeaderToolbar>
          <CustomizedSnackbars
            open={isErrorMessageOpen}
            setOpen={setIsErrorMessageOpen}
            message={intl.formatMessage({ id: 'server.error' })}
            variant="error"
            verticalAlign="top"
          />
          <DropdownMenu>
            <DropDownMenuItems
              handleEditSubmit={updateApp(application.id)}
              handleDeleteSubmit={() => deleteApp(application.id)}
              handleReload={reload}
              application={application}
            />
          </DropdownMenu>
        </PortletHeaderToolbar>
      }
    />
  );
}
