import React, { useState } from 'react';
import Toggler from '../../../../../ui/Toggler';
import { getTaskLogsForCurrentDay } from '../../../../../crud/application.crud';
import { Alert } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import './style.scss';

export default function DevModeToggler({ application, setApplication }) {
  const intl = useIntl();
  const [warning, setWarning] = useState(null);

  const onClick = async isChecked => {
    setApplication({ ...application, isDevMode: isChecked });

    if (!isChecked) {
      setWarning(null);
      return;
    }

    const logsCount = await getTaskLogsForCurrentDay(application.id);

    if (logsCount > application.freeLogs) {
      setWarning(
        intl.formatMessage(
          { id: 'settings.charge_full_day' },
          { count: application.freeLogs },
        ),
      );
    }
  };
  return (
    <div className="pr-3">
      <Alert
        show={warning}
        variant="warning"
        className="text-white"
        dismissible={true}
        onClose={() => setWarning(null)}
      >
        {warning}
      </Alert>
      <div className="d-flex justify-content-end">
        <Toggler
          label="Development mode"
          value={application.isDevMode}
          onChange={e => onClick(e.target.checked)}
        />
      </div>
    </div>
  );
}
