import React from 'react';

export default function KeyValueBlock({ keyName, value }) {
  return (
    <div className="d-flex justify-content-between">
      <p className="invoice-text col-6 text-end">{keyName}</p>
      <p className="invoice-text col-6 text-end">{value}</p>
    </div>
  );
}
