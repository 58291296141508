import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import ReplenishConfirm from './ReplenishConfirm';
import CreatePaymentIntentForm from './CreatePaymentIntentForm';
import * as paymentService from '../../../../crud/payment.crud';

export default function ReplenishBalance({
  show,
  onHide,
  paymentMethods,
  balance,
  setMessage,
}) {
  const intl = useIntl();
  const stripe = useStripe();
  const [amount, setAmount] = useState(balance < 0 ? 0 - balance / 100 : 10);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const createPaymentIntent = async () => {
    try {
      const { clientSecret } = await paymentService.createPaymentIntent(
        amount * 100,
      );
      setClientSecret(clientSecret);
    } catch (e) {
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  const onConfirm = async () => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.stripeToken,
    });

    if (result.error) {
      setMessage({
        open: true,
        variant: 'error',
        text: result.error.message,
      });
    } else {
      setMessage({
        open: true,
        variant: 'success',
        text: intl.formatMessage({ id: 'payments.replenish_success' }),
      });
    }

    setClientSecret(null);
    onHide();
  };

  return (
    <Dialog
      open={show}
      PaperProps={{ className: 'p-3' }}
      onBackdropClick={onHide}
      onClose={onHide}
      fullWidth={true}
      maxWidth="xs"
    >
      <h4 className="text-center mb-3">
        <FormattedMessage id="payments.replenish_balance" />
      </h4>
      <CreatePaymentIntentForm
        onAmountChange={(value, name) => setAmount(value)}
        onMethodChange={event => setPaymentMethod(event.target.value)}
        clientSecret={clientSecret}
        onCancel={onHide}
        setClientSecret={setClientSecret}
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        amount={amount}
        onSubmit={createPaymentIntent}
      />
      {clientSecret && (
        <ReplenishConfirm
          onCancel={() => {
            setClientSecret(null);
            onHide();
          }}
          onConfirm={onConfirm}
        />
      )}
    </Dialog>
  );
}
