import React from 'react';
import * as paymentService from '../../../../crud/payment.crud';
import SpinnerButton from '../../../../ui/SpinnerButton';
import { useIntl, FormattedMessage } from 'react-intl';
import Card from './Card';

export default function PaymentCard({ method, refreshMethods, setMessage }) {
  const intl = useIntl();

  const makeDefault = async () => {
    try {
      await paymentService.changeDefaultPaymentMethod(method.id);
      await refreshMethods();

      setMessage({
        open: true,
        text: intl.formatMessage({ id: 'payments.operation_success' }),
        variant: 'success',
      });
    } catch (e) {
      console.log(e);

      setMessage({
        open: true,
        text: intl.formatMessage({ id: 'server.error' }),
        variant: 'success',
      });
    }
  };

  const deleteMethod = async () => {
    try {
      await paymentService.deletePaymentMethod(method.id);
      await refreshMethods();
      setMessage({
        open: true,
        text: intl.formatMessage({ id: 'payments.card_delete_success' }),
        variant: 'success',
      });
    } catch (e) {
      console.log(e);

      setMessage({
        open: true,
        text: intl.formatMessage({ id: 'server.error' }),
        variant: 'success',
      });
    }
  };

  return (
    <div className="col-xl-6 col-md-10 col-sm-12">
      <Card method={method}>
        <div className="d-flex justify-content-between">
          {!method.isPrimary && (
            <SpinnerButton
              className="ml-2"
              size="sm"
              variant="secondary"
              onClick={makeDefault}
            >
              <FormattedMessage id="payments.make_default" />
            </SpinnerButton>
          )}
          <SpinnerButton
            className="ml-2"
            size="sm"
            variant="danger"
            onClick={deleteMethod}
          >
            <FormattedMessage id="delete" />
          </SpinnerButton>
        </div>
      </Card>
    </div>
  );
}
