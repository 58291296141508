export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getTimePast(milliseconds) {
  const hours = new Date(milliseconds).getUTCHours().toString();
  const hoursString = (hours.length === 1 ? '0' : '') + hours;
  const minutes = new Date(milliseconds).getMinutes().toString();
  const minutesString = (minutes.length === 1 ? '0' : '') + minutes;
  const seconds = new Date(milliseconds).getSeconds().toString();
  const secondsString = (seconds.length === 1 ? '0' : '') + seconds;

  return `${hoursString}:${minutesString}:${secondsString}`;
}

export function getInterval(startDate, endDate) {
  return `${new Date(startDate).toLocaleDateString()} - ${new Date(
    endDate,
  ).toLocaleDateString()}`;
}

export const centToDollars = cents =>
  cents < 0
    ? `-$${Math.abs(cents / 100).toFixed(2)}`
    : `$${(cents / 100).toFixed(2)}`;
