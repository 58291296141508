import React from 'react';
import ProfileUpdate from './ProfileUpdate';
import { useIntl } from 'react-intl';
import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../partials/content/Portlet';

export function Profile(props) {
  const intl = useIntl();
  return (
    <Portlet>
      <PortletHeader title={intl.formatMessage({ id: 'client.profile' })} />
      <PortletBody>
        <ProfileUpdate />
      </PortletBody>
    </Portlet>
  );
}
