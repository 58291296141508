import React from 'react';
import { makeStyles } from '@material-ui/core';

export const noContentStyle = makeStyles(() => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    fontSize: '3rem',
  },
}));

export default function NoContent({ children }) {
  const noContentClasses = noContentStyle();
  return (
    <div className={`${noContentClasses.main} mt-5`}>
      <span style={{ fontSize: '2rem', textTransform: 'uppercase' }}>
        {children}
      </span>
    </div>
  );
}
