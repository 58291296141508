import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import * as auth from '../../store/reducers/authReducer';
import { login } from '../../crud/auth.crud';

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  return (
    <>
      <div className="kt-login__head">
        <span className="kt-login__signup-label">
          <FormattedMessage id="auth.general.no_account" />
        </span>
        &nbsp;&nbsp;
        <Link
          to="/auth/registration"
          className="kt-link kt-login__signup-link"
          onClick={() => props.unRegister()}
        >
          <FormattedMessage id="auth.general.signup_button" />
        </Link>
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="auth.login.title" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: '',
              isPerpetual: false,
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: 'auth.validation.required_field',
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: 'auth.validation.invalid_field',
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: 'auth.validation.required_field',
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                login(values.email, values.password, values.isPerpetual)
                  .then(data => {
                    disableLoading();
                    props.login(data.data);
                  })
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: 'auth.validation.invalid_login',
                      }),
                    );
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : null}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <div className="form-group mb-0 ml-1">
                    <FormControlLabel
                      label={<FormattedMessage id="auth.login.valid_time" />}
                      control={
                        <Checkbox
                          color="primary"
                          name="isPerpetual"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values.isPerpetual}
                          value={values.isPerpetual}
                        />
                      }
                    />
                  </div>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                      },
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id="auth.login.button" />
                  </button>
                </div>
                <div className="text-center">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="auth.general.forgot_button" />
                  </Link>
                </div>
              </form>
            )}
          </Formik>

          {/* <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div> */}

          {/* <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
