import React, { useState } from 'react';
import AppModalForm from '../../ModalForms/AppModalForm';
import { AppDeleteModalForm } from '../../ModalForms/AppDeleteModalForm';
import { useIntl, FormattedMessage } from 'react-intl';

export const DropDownMenuItems = ({
  handleEditSubmit,
  handleDeleteSubmit,
  handleReload,
  application,
}) => {
  const intl = useIntl();
  const [isEditWindowOpen, setIsEditWindowOpen] = useState(false);
  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState(false);

  return (
    <div>
      <li
        onClick={() => {
          handleReload();
          document.dispatchEvent(new MouseEvent('click'));
        }}
        className="kt-nav__item"
      >
        <div className="kt-nav__link">
          <i className="kt-nav__link-icon flaticon2-refresh-button"></i>
          <span className="kt-nav__link-text">
            <FormattedMessage id="applications.reload" />
          </span>
        </div>
      </li>
      <li onClick={() => setIsEditWindowOpen(true)} className="kt-nav__item">
        <div className="kt-nav__link">
          <i className="kt-nav__link-icon flaticon-edit-1"></i>
          <span className="kt-nav__link-text">
            <FormattedMessage id="edit" />
          </span>
        </div>
      </li>
      <AppModalForm
        open={isEditWindowOpen}
        handleClose={() => setIsEditWindowOpen(false)}
        initialValues={{
          name: application.name,
          description: application.description,
        }}
        handleSubmit={handleEditSubmit}
        dialogTitle={intl.formatMessage({
          id: 'edit',
        })}
        descriptionText={intl.formatMessage({
          id: 'application.edit.description',
        })}
        failStatus={intl.formatMessage({
          id: 'server.error',
        })}
      />
      <li onClick={() => setIsDeleteWindowOpen(true)} className="kt-nav__item">
        <div className="kt-nav__link">
          <i className="kt-nav__link-icon flaticon-delete"></i>
          <span className="kt-nav__link-text">
            <FormattedMessage id="delete" />
          </span>
        </div>
      </li>
      <AppDeleteModalForm
        open={isDeleteWindowOpen}
        handleClose={() => setIsDeleteWindowOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
    </div>
  );
};
