import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export default function SpinnerButton({
  children,
  disabled,
  onClick,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await onClick();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const intl = useIntl();
  return (
    <Button {...props} onClick={handleClick} disabled={isLoading || disabled}>
      {isLoading ? (
        <>
          {intl.formatMessage({ id: 'loading' })}{' '}
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </>
      ) : (
        children
      )}
    </Button>
  );
}
