import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SpinnerButton from '../../../../ui/SpinnerButton';

export default function ReplenishConfirm({ onCancel, onConfirm }) {
  return (
    <div>
      <p>
        <FormattedMessage id="payments.replenish_confirm" />
      </p>
      <div className="d-flex justify-content-between">
        <Button onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>
        <SpinnerButton onClick={onConfirm}>Confirm</SpinnerButton>
      </div>
    </div>
  );
}
