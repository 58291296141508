import React from 'react';
import { FormattedMessage } from 'react-intl';
import KeyValueBlock from '../../../../../ui/KeyValueBlock';
import { getInterval } from '../../../../../utils/textUtils';

export default function InvoiceData({ user, invoice }) {
  return (
    <div className="d-flex justify-content-between align-items-end mb-3">
      <section className="col-6">
        <p className="invoice-text uppercase">
          <FormattedMessage id="payments.bill_to" />
        </p>
        <hr />
        <KeyValueBlock
          keyName={<FormattedMessage id="payments.customer_name" />}
          value={<strong>{user.fullName}</strong>}
        />
        <KeyValueBlock
          keyName={<FormattedMessage id="payments.customer_email" />}
          value={<strong>{user.email}</strong>}
        />
      </section>
      <section className="invoices-date col-4">
        <KeyValueBlock
          keyName={<FormattedMessage id="payments.invoice_date" />}
          value={new Date(invoice.created).toLocaleDateString()}
        />
        <KeyValueBlock
          keyName={<FormattedMessage id="payments.due_date" />}
          value={getInterval(invoice.startDate, invoice.endDate)}
        />
      </section>
    </div>
  );
}
