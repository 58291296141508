import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { injectIntl, useIntl, FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import { SELF, CLOUD } from '../../../constants/AppConstants';

function AppModalForm({
  open,
  handleClose,
  handleSubmit,
  dialogTitle,
  descriptionText,
  initialValues,
  failStatus,
}) {
  const intl = useIntl();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="app-form-dialog-title"
    >
      <div
        className="pl-5 pr-5 pt-3 pb-3"
        style={{
          fontSize: '1.25rem',
          fontFamily: 'Poppins',
          fontWeight: '500',
          lineHeight: '1.6',
        }}
      >
        {dialogTitle}
      </div>
      <DialogContentText
        id="app-form-dialog-title"
        className="pl-5 pr-5 pt-3 pb-3"
      >
        {descriptionText}
      </DialogContentText>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: 'form.validation.required',
            });
          } else if (values.name.length < 5) {
            errors.name = intl.formatMessage(
              { id: 'form.validation.min_length' },
              { min: '5' },
            );
          }

          if (!values.description) {
            errors.description = intl.formatMessage({
              id: 'form.validation.required',
            });
          } else if (values.description.length < 5) {
            errors.description = intl.formatMessage(
              { id: 'form.validation.min_length' },
              { min: '5' },
            );
          }

          return errors;
        }}
        onSubmit={async (values, { setStatus }) => {
          const isOk = await handleSubmit(values);
          isOk ? handleClose() : setStatus(failStatus);
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            className="kt-form pl-5 pr-5 pb-3 pt-3"
            onSubmit={handleSubmit}
          >
            {status && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{status}</div>
              </div>
            )}

            <div className="form-group">
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                type="text"
                label={intl.formatMessage({ id: 'name' })}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
              />
            </div>

            <div className="form-group">
              <TextField
                margin="dense"
                className="m-0 w-100"
                variant="outlined"
                fullWidth
                multiline
                type="text"
                label={intl.formatMessage({ id: 'description' })}
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                helperText={touched.description && errors.description}
                error={Boolean(touched.description && errors.description)}
              />
            </div>
            {!initialValues.name && (
              <>
                <InputLabel htmlFor="outlined-pricingModel">
                  <FormattedMessage id="settings.pricingModel" />
                </InputLabel>
                <FormControl variant="outlined" className="w-100">
                  <Select
                    value={values.pricingModel}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        notched={true}
                        name="pricingModel"
                        id="outlined-pricingModel"
                      />
                    }
                  >
                    <MenuItem value={SELF}>
                      <FormattedMessage id="settings.self" />
                    </MenuItem>
                    <MenuItem value={CLOUD}>
                      <FormattedMessage id="settings.cloud" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            )}

            <div className="kt-login__actions text-right">
              <Button color="primary" type="submit">
                <FormattedMessage id="submit" />
              </Button>
              <Button color="primary" onClick={handleClose} type="button">
                <FormattedMessage id="close" />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
export default injectIntl(AppModalForm);
