import React, { useState, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TableHead } from '@material-ui/core';
import Pagination from '../../../applications/application/taskList/TaskTable/Pagination';
import { centToDollars } from '../../../../utils/textUtils';
import { tableStyles } from '../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import * as paymentService from '../../../../crud/payment.crud';
import InvoicePreviewContainer from '../Invoices/InvoicePreviewContainer';
import NoContent from '../../../../ui/NoContent';

export default function Charges({ setMessage }) {
  const intl = useIntl();
  const classes = tableStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [charges, setCharges] = useState([]);
  const [count, setCount] = useState(0);

  const fetchCharges = async (page, limit) => {
    const result = await paymentService.getPaginatedCharges(page, limit);
    if (result) {
      const [charges, count] = result;
      setCharges(charges);
      setCount(count);
    } else {
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  useEffect(() => {
    fetchCharges(1);
  }, []);

  if (!count) {
    return (
      <NoContent>
        <FormattedMessage id="payments.no_charges" />
      </NoContent>
    );
  }

  return (
    <div>
      <Table className={classes.tableWrapper}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage id="payments.date" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.application_name" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.monthly_price" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.users_number" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.created" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="payments.cost" />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id="payments.invoice" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {charges &&
            charges.map((charge, index) =>
              index < rowsPerPage ? (
                <TableRow key={charge.id}>
                  <TableCell component="th" scope="row" align="left">
                    {new Date(charge.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">{charge.application.name}</TableCell>
                  <TableCell align="left">
                    {centToDollars(charge.monthlyPrice)}
                  </TableCell>
                  <TableCell align="left">{charge.usersNumber}</TableCell>
                  <TableCell align="left">
                    {new Date(charge.created).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    {centToDollars(charge.cost)}
                  </TableCell>
                  <TableCell align="center">
                    {charge.invoice ? (
                      <InvoicePreviewContainer invoice={charge.invoice} />
                    ) : (
                      <FormattedMessage id="payments.no_invoice" />
                    )}
                  </TableCell>
                </TableRow>
              ) : null,
            )}
        </TableBody>
      </Table>
      <Pagination
        count={count}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetchRows={fetchCharges}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}
