import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const PortletHeaderDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      id="kt_dashboard_daterangepicker"
      type="button"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      className="btn btn-clean btn-sm btn-icon btn-icon-lg"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="flaticon-more-1"></i>
    </button>
  );
});
export function DropdownMenu({ children }) {
  return (
    <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
      <Dropdown.Toggle
        as={PortletHeaderDropdownToggle}
        id="dropdown-toggle-top"
      >
        <i className="flaticon-more-1" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
        <ul className="kt-nav">
          { children }
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
