import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ListGroup, Tab } from 'react-bootstrap';
import { Switch, Route, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import AllPaymentCards from './PaymentMethods/AllPaymentCards';
import AddCard from './PaymentMethods/AddCard';
import Invoices from './Invoices/Invoices';
import { FormattedMessage } from 'react-intl';
import PaymentsList from './PaymentsList/PaymentsList.js';
import Charges from './Charges/Charges';

export default function Menu({ paymentMethods, refreshMethods, setMessage }) {
  return (
    <div className="d-md-flex">
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#charges">
        <div className="col-xl-2 col-md-3 col-sm-12">
          <Paper className="p-3 mb-3">
            <ListGroup>
              <ListGroup.Item action href="#charges">
                <FormattedMessage id="payments.charges" />
              </ListGroup.Item>
              <ListGroup.Item action href="#invoices">
                <FormattedMessage id="payments.invoices" />
              </ListGroup.Item>
              <ListGroup.Item action href="#payments">
                <FormattedMessage id="payments.payments" />
              </ListGroup.Item>
              <ListGroup.Item action href="#payment-methods">
                <FormattedMessage id="payments.methods" />
              </ListGroup.Item>
            </ListGroup>
          </Paper>
        </div>
        <Paper className="w-100 ml-2 overflow-auto">
          <Tab.Content>
            <Tab.Pane eventKey="#payment-methods">
              <AllPaymentCards
                paymentMethods={paymentMethods}
                refreshMethods={refreshMethods}
                setMessage={setMessage}
              />
              <hr />
              <AddCard
                setMessage={setMessage}
                refreshMethods={refreshMethods}
                isPrimary={paymentMethods && paymentMethods.length === 0}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="#invoices">
              <Invoices setMessage={setMessage} />
            </Tab.Pane>
            <Tab.Pane eventKey="#charges">
              <Charges setMessage={setMessage} />
            </Tab.Pane>
            <Tab.Pane eventKey="#payments">
              <PaymentsList setMessage={setMessage} />
            </Tab.Pane>
          </Tab.Content>
        </Paper>
      </Tab.Container>
    </div>
  );
}
