import React, { useState } from 'react';

import { updateApplication } from '../../../../crud/application.crud';
import { CustomizedSnackbars } from '../../../../ui/SnackBar';
import { useIntl, FormattedMessage } from 'react-intl';
import AllSettingsLines from './AllSettingsLines';
import { Button } from 'react-bootstrap';
import Instructions from './Instructions';
import DevModeToggler from './DevModeToggler';

export function ApplicationSettings({
  application,
  setApplication,
  fetchApplication,
}) {
  const intl = useIntl();
  const [isSuccessMesOpen, setIsSuccessMesOpen] = useState(false);
  const [isErrorMesOpen, setIsErrorMesOpen] = useState(false);

  const updateApp = async app => {
    const body = { ...app };
    delete body.id;
    delete body.created;
    delete body.pricingModel;
    const response = await updateApplication(app.id, body);
    if (response) {
      await fetchApplication();
      setIsSuccessMesOpen(true);
    } else {
      setIsErrorMesOpen(true);
    }
  };

  return (
    <div style={{ fontSize: 15, marginTop: '24px' }}>
      <CustomizedSnackbars
        open={isSuccessMesOpen}
        setOpen={setIsSuccessMesOpen}
        message={intl.formatMessage({ id: 'settings.saved' })}
        variant="success"
        verticalAlign="bottom"
      />
      <CustomizedSnackbars
        open={isErrorMesOpen}
        setOpen={setIsErrorMesOpen}
        message={intl.formatMessage({ id: 'server.error' })}
        variant="error"
        verticalAlign="bottom"
      />
      <DevModeToggler
        application={application}
        setApplication={setApplication}
      />

      <AllSettingsLines
        application={application}
        setApplication={setApplication}
      />
      <Instructions application={application} setApplication={setApplication} />
      <div className="text-center">
        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            updateApp(application);
          }}
        >
          <FormattedMessage id="save" />
        </Button>
      </div>
    </div>
  );
}
