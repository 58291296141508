import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

function Toggler({ label, value, onChange, ...props }) {
  return (
    <FormControl component="fieldset" {...props}>
      <FormControlLabel
        checked={value}
        control={<Switch color="primary" />}
        label={label}
        onChange={onChange}
        labelPlacement="start"
      />
    </FormControl>
  );
}

export default Toggler;
