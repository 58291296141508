import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  getAppById,
  updateApplication,
  deleteApplication,
} from '../../../crud/application.crud';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store/reducers/applicationsReducer';

export const useApplication = id => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { application, isSettingsChanged } = useSelector(
    ({ applications }) => applications,
  );
  const [applicationForComparing, setApplicationForComparing] = useState(null);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [isWebhookError, setIsWebhookError] = useState(false);

  const setApplication = application => {
    dispatch(actions.setApplication(application));
  };

  const setIsApplicationDeletedMessage = bool =>
    dispatch(actions.setIsApplicationDeletedMessage(bool));

  const setIsSettingsChanged = bool =>
    dispatch(actions.setIsSettingsChanged(bool));

  const reload = async () => {
    fetchApplication();
  };

  const fetchApplication = async () => {
    const responseData = await getAppById(id);
    if (responseData) {
      // this is application for change
      setApplication(responseData);
      // this is deep copy to compare changes
      setApplicationForComparing(JSON.parse(JSON.stringify(responseData)));

      setIsSettingsChanged(false);

      if (responseData.hasNewErrors) {
        setIsWebhookError(true);
      }
    } else {
      history.push('/applications');
    }
  };

  const updateApp = id => {
    return async body => {
      const response = await updateApplication(id, body);
      if (response) {
        await fetchApplication();

        return true;
      } else {
        return false;
      }
    };
  };

  const deleteApp = async id => {
    const response = await deleteApplication(id);

    if (response) {
      setIsApplicationDeletedMessage(true);
      history.push('/applications');
    } else {
      setIsErrorMessageOpen(true);
    }
  };

  const onAlertClose = async () => {
    try {
      await updateApplication(id, { hasNewErrors: false });
    } catch (e) {
      console.log(e);
      setIsErrorMessageOpen(true);
    }
    setIsWebhookError(false);
  };

  useEffect(() => {
    if (!application) {
      return;
    }

    let isChanged = false;
    for (const prop in applicationForComparing) {
      if (applicationForComparing[prop] !== application[prop]) {
        isChanged = true;
        break;
      }
    }

    setIsSettingsChanged(isChanged);
  }, [application]);

  useEffect(() => {
    fetchApplication();

    return () => setApplication(null);
  }, []);

  return {
    application,
    setApplication,
    isErrorMessageOpen,
    setIsErrorMessageOpen,
    isSettingsChanged,
    setIsSettingsChanged,
    fetchApplication,
    updateApp,
    deleteApp,
    reload,
    onAlertClose,
    isWebhookError,
  };
};
