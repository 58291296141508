import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styles from './style.module.scss';
import {
  InputLabel,
  FormControl,
  Select,
  FilledInput,
  MenuItem,
} from '@material-ui/core';
import Card from '../PaymentMethods/Card';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SpinnerButton from '../../../../ui/SpinnerButton';
import ErrorText from '../../../../ui/ErrorText';
import { useAmountErrors } from './useAmountErrors';

export default function CreatePaymentIntentForm({
  clientSecret,
  onMethodChange,
  onAmountChange,
  onCancel,
  paymentMethod,
  paymentMethods,
  amount,
  onSubmit,
}) {
  const [isSelect, setIsSelect] = useState(false);

  const amountError = useAmountErrors(amount);

  return (
    <form className="kt-form d-flex flex-column">
      <div className={`form-group align-self-center ${styles.input}`}>
        <CurrencyInput
          className="form-control"
          id="input-example"
          name="input-name"
          placeholder="$10,00"
          allowDecimals={true}
          prefix="$"
          value={amount}
          decimalsLimit={2}
          onChange={onAmountChange}
        />
        <ErrorText text={amountError} />
      </div>
      <FormControl variant="filled" className="mb-3">
        <InputLabel htmlFor="filled-card-simple" className="m-2" margin="dense">
          Card
        </InputLabel>
        {paymentMethods && (
          <Select
            disabled={Boolean(clientSecret)}
            open={isSelect}
            onOpen={() => setIsSelect(true)}
            onClose={() => setIsSelect(false)}
            value={paymentMethod || ''}
            onChange={onMethodChange}
            input={<FilledInput name="card" id="filled-card-simple" />}
          >
            {paymentMethods.map(method => (
              <MenuItem
                selected={method.isPrimary}
                value={method}
                key={method.id}
                className="d-flex justify-content-between"
              >
                <div className="w-100">
                  <Card method={method} />
                </div>
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      {!clientSecret && (
        <div className="d-flex justify-content-between">
          <Button onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>
          <SpinnerButton
            disabled={!paymentMethod || amountError}
            onClick={onSubmit}
          >
            <FormattedMessage id="ok" />
          </SpinnerButton>
        </div>
      )}
    </form>
  );
}
