import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

const SettingsLine = ({ title, id, children, translationValues }) => {
  const intl = useIntl();
  return (
    <div className="form-group row">
      <label
        htmlFor={id}
        className="col-sm-3 col-form-label d-flex align-items-center"
      >
        <FormattedMessage id={`settings.${id}`} />
      </label>
      <div
        className="col-sm-4"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
      <div className="col-sm-5">
        <FormattedMessage
          id={`settings.${id}.description`}
          values={translationValues}
        />
      </div>
    </div>
  );
};

export default SettingsLine;
