import React, { useEffect, useState } from 'react';
import * as paymentService from '../../../../crud/payment.crud';
import { Modal } from 'react-bootstrap';
import Charges from './Charges';
import PrintCharges from './PrintMode';

export default function InvoicePreviewModal({ show, setShow, invoice }) {
  const [charges, setCharges] = useState(null);
  const [count, setCount] = useState(0);
  const [isPrintMode, setIsPrintMode] = useState(false);

  const createChargeGetter = id => {
    return async (pageNumber = 1, limit = 10) => {
      const chargesAndCount = await paymentService.getPaginatedChargesByInvoice(
        id,
        pageNumber,
        limit,
      );

      if (chargesAndCount) {
        const [charges, count] = chargesAndCount;
        setCharges(charges);
        setCount(count);
      } else {
        console.log('server error');
      }
    };
  };

  useEffect(() => {
    if (show) {
      createChargeGetter(invoice.id)();
    }
  }, [show]);

  return (
    <Modal
      style={{ padding: '2rem' }}
      size="xl"
      centered={true}
      backdrop={true}
      show={show}
      onEscapeKeyDown={() => setShow(false)}
      onHide={() => setShow(false)}
    >
      {isPrintMode ? (
        <PrintCharges invoice={invoice} setIsPrintMode={setIsPrintMode} />
      ) : (
        <Charges
          charges={charges}
          count={count}
          fetchRows={createChargeGetter(invoice.id)}
          setIsPrintMode={setIsPrintMode}
          invoice={invoice}
        />
      )}
    </Modal>
  );
}
