import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;
const LOGIN_URL = BASE_URL + '/api/client/login';
const LOGOUT_URL = BASE_URL + '/api/client/logout';
const REGISTER_URL = BASE_URL + '/api/client/signup';
const REQUEST_PASSWORD_URL = BASE_URL + '/api/client/recover-password';
const ME_URL = BASE_URL + '/api/client/check';

export function login(email, password, isPerpetual) {
  try {
    return axios.post(LOGIN_URL, { email, password, isPerpetual });
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function userLogout() {
  try {
    const response = await axios.delete(LOGOUT_URL);
    return response;
  } catch (e) {
    console.log(e);
  }
}

export function register(email, fullName, password) {
  return axios.post(REGISTER_URL, {
    email,
    password,
    fullName,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function checkUser() {
  try {
    const response = await axios.get(ME_URL);
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
