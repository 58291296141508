import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableHead } from '@material-ui/core';
import Pagination from '../../../applications/application/taskList/TaskTable/Pagination';
import { centToDollars } from '../../../../utils/textUtils';
import { tableStyles } from '../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import * as paymentService from '../../../../crud/payment.crud';
import NoContent from '../../../../ui/NoContent';

export default function PaymentsList({ setMessage }) {
  const intl = useIntl();
  const classes = tableStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [payments, setPayments] = useState([]);
  const [count, setCount] = useState(0);

  const fetchPayments = async (page, limit) => {
    const result = await paymentService.getPaginatedPayments(page, limit);
    if (result) {
      const [payments, count] = result;
      setPayments(payments);
      setCount(count);
    } else {
      setMessage({
        open: true,
        variant: 'error',
        text: intl.formatMessage({ id: 'server.error' }),
      });
    }
  };

  useEffect(() => {
    fetchPayments(1);
  }, []);

  if (!count) {
    return (
      <NoContent>
        <FormattedMessage id="payments.no_payments" />
      </NoContent>
    );
  }

  return (
    <Paper>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <FormattedMessage id="payments.created" />
              </TableCell>
              <TableCell align="left">
                <FormattedMessage id="payments.amount" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments &&
              payments.map((payment, index) =>
                index < rowsPerPage ? (
                  <TableRow key={payment.id}>
                    <TableCell align="left">
                      {new Date(payment.created).toLocaleString()}
                    </TableCell>
                    <TableCell align="left">
                      {centToDollars(payment.amount)}
                    </TableCell>
                  </TableRow>
                ) : null,
              )}
          </TableBody>
        </Table>
        <Pagination
          count={count}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fetchRows={fetchPayments}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </Paper>
  );
}
