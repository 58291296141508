import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, useIntl } from 'react-intl';
import TaskLogRow from './TaskLogRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  noLogs: {
    textAlign: 'center',
    fontSize: '2rem',
  },
}));

export default function TaskLogTable({ logs }) {
  const classes = useStyles();

  return logs.length === 0 ? (
    <div className={classes.noLogs}>
      <FormattedMessage id="tasks.log.noLogs" />
    </div>
  ) : (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="tasks.created" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.answer" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.isTimeout" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.isSkipped" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.isDeclined" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.timeToAnswer" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.user" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="tasks.log.decline" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map(log => (
            <TaskLogRow log={log} key={log.id} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
