import React from 'react';
import MailSent from './MailSent';
import { useIntl } from 'react-intl';

const PasswordRecover = () => {
  const intl = useIntl();
  return (
    <MailSent
      text={intl.formatMessage({
        id: 'auth.forgot.success',
      })}
    />
  );
};

export default PasswordRecover;
