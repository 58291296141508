import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import classes from '../Application.module.scss';
import { Button } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import htmlToDraft from 'html-to-draftjs';

const Instructions = ({ application, setApplication }) => {
  const intl = useIntl();
  const noInstructions = intl.formatMessage({ id: 'settings.no.instructions' });
  const [isEdit, setIsEdit] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const convertHtmlToDraft = () => {
    const blocksFromHtml = htmlToDraft(application.instructions);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    setEditorState(EditorState.createWithContent(contentState));
  };

  const onSubmit = () => {
    const content = editorState.getCurrentContent();

    if (content.hasText()) {
      setApplication({
        ...application,
        instructions: draftToHtml(convertToRaw(content)),
      });
    } else {
      setApplication({
        ...application,
        instructions: null,
      });
    }
    setIsEdit(false);
  };

  const onCancel = () => {
    if (application.instructions) {
      convertHtmlToDraft();
    }

    setIsEdit(false);
  };

  useEffect(() => {
    if (application.instructions) {
      convertHtmlToDraft();
    }
  }, []);

  return (
    <div className="mt-5 mb-5">
      <div className="ml-3 mt-5 mb-5 text-center h3">
        <FormattedMessage id="settings.instructions" />
      </div>
      {isEdit ? (
        <Editor
          editorState={editorState}
          editorClassName={classes['editor-wrapper']}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: [
              'inline',
              'link',
              'list',
              'blockType',
              'history',
              'remove',
              'textAlign',
              'fontSize',
            ],
            inline: {
              options: ['bold', 'italic', 'underline', 'monospace'],
            },
          }}
          toolbarHidden={!isEdit}
          readOnly={!isEdit}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: application.instructions || noInstructions,
          }}
        ></div>
      )}
      {isEdit ? (
        <div className="d-flex justify-content-between">
          <Button variant="info" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="success" onClick={onSubmit}>
            <FormattedMessage id="submit" />
          </Button>
        </div>
      ) : (
        <div className="text-right">
          <Button variant="success" onClick={() => setIsEdit(true)}>
            <FormattedMessage id="edit" />
          </Button>
        </div>
      )}
      <hr />
    </div>
  );
};
export default Instructions;
