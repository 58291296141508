import axios from 'axios';
axios.defaults.withCredentials = true;

const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;
const APPS_URL = BASE_URL + '/api/applications';
const TASKS_URL = BASE_URL + '/api/tasks';

export async function getApplications() {
  try {
    const response = await axios.get(APPS_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getAppById(id) {
  const APP_URL = `${APPS_URL}/${id}`;

  try {
    const response = await axios.get(APP_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function createApplication(dto) {
  try {
    const response = await axios.post(APPS_URL, dto);

    return response;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function updateApplication(id, body) {
  const APP_URL = `${APPS_URL}/${id}`;
  try {
    const response = await axios.put(APP_URL, body);

    return response;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function deleteApplication(id) {
  const APP_URL = `${APPS_URL}/${id}`;
  try {
    const response = await axios.delete(APP_URL);

    return response;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getTasksByApplicationId(id, page = 1, limit = 10) {
  const TASKS_URL = `${APPS_URL}/${id}/tasks?page=${page}&limit=${limit}`;
  try {
    const response = await axios.get(TASKS_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getTaskLogByTaskId(id) {
  const TASK_LOGS_URL = `${TASKS_URL}/${id}/logs`;
  try {
    const response = await axios.get(TASK_LOGS_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function getUsersByApplicationId(id, page = 1, limit = 10) {
  const USERS_URL = `${APPS_URL}/${id}/users?page=${page}&limit=${limit}`;
  try {
    const response = await axios.get(USERS_URL);
    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function inviteUserToApplication(values, applicationId) {
  const INVITE_URL = `${APPS_URL}/${applicationId}/invite`;
  try {
    const response = await axios.post(INVITE_URL, values);
    return response;
  } catch (e) {
    return e.response;
  }
}

export async function removeUserFromApplication(applicationId, userId) {
  const REMOVE_URL = `${APPS_URL}/${applicationId}/delete/${userId}`;
  try {
    const response = await axios.delete(REMOVE_URL, userId);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
}

export async function blockUserFromApplication(applicationId, userId) {
  const BLOCK_URL = `${APPS_URL}/${applicationId}/block/${userId}`;
  try {
    const response = await axios.put(BLOCK_URL, userId);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
}

export async function unblockUserFromApplication(applicationId, userId) {
  const UNBLOCK_URL = `${APPS_URL}/${applicationId}/unblock/${userId}`;
  try {
    const response = await axios.put(UNBLOCK_URL, userId);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
}

export async function getTaskLogsForCurrentDay(id) {
  const TASK_LOGS_URL = `${APPS_URL}/${id}/taskLogs`;
  try {
    const response = await axios.get(TASK_LOGS_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}

export async function regenerateInviteToken(id) {
  const INVITE_TOKEN_URL = `${APPS_URL}/${id}/inviteToken`;
  try {
    const response = await axios.put(INVITE_TOKEN_URL);

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}
export async function declineTaskLog(applicationId, taskLogId, declineReason) {
  const DECLINE_TASK_URL = `${APPS_URL}/${applicationId}/taskLogs/${taskLogId}`;
  try {
    const response = await axios.put(DECLINE_TASK_URL, { declineReason });

    return response.data;
  } catch (e) {
    console.log(e);

    return null;
  }
}
