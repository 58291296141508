import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import UserStatus from './UserStatus';
import ChangeUserStatusModal from './ChangeUserStatusModal';
import { FormattedMessage } from 'react-intl';
import { useUsers } from './useUsers';

const UserRow = ({
  application,
  userData,
  fetchUsers,
  setIsErrorMessageOpen,
}) => {
  const { user } = userData;
  const { removeUser, blockUser, unblockUser } = useUsers(
    fetchUsers,
    setIsErrorMessageOpen,
  );

  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell align="right">
        {`${user.firstName} ${user.lastName}`}
      </TableCell>
      <TableCell align="right">
        <UserStatus
          isUserBlocked={userData.isUserBlocked}
          connections={user.connections}
        />
      </TableCell>
      <TableCell align="right">{userData.incorrectAnswers}</TableCell>
      <TableCell align="right">{userData.declinedAnswers}</TableCell>
      <TableCell align="right">
        <ChangeUserStatusModal
          handleSubmit={() => {
            if (userData.isUserBlocked) {
              unblockUser(application.id, user.id);
            } else {
              blockUser(application.id, user.id);
            }
          }}
          title={
            <FormattedMessage
              id={userData.isUserBlocked ? 'users.unblock' : 'users.block'}
            />
          }
          buttonVariant="secondary"
          description={
            <FormattedMessage
              id={
                userData.isUserBlocked
                  ? 'application.user.unblock'
                  : 'application.user.block'
              }
            />
          }
        />
      </TableCell>
      <TableCell align="right">
        <ChangeUserStatusModal
          handleSubmit={() => {
            removeUser(application.id, user.id);
          }}
          title={<FormattedMessage id="users.remove" />}
          buttonVariant="danger"
          description={<FormattedMessage id="application.user.delete" />}
        />
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
