import React from 'react';
import { FullWidthTabs, TabContainer } from '../../../ui/FullWidthTabs';
import { ApplicationSettings } from './ApplicationSettings/ApplicationSettngs';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaskList } from './taskList/TaskList';
import Users from './Users/Users';
import classes from './Application.module.scss';

export default function Tabs({
  application,
  setApplication,
  fetchApplication,
  setIsErrorMessageOpen,
}) {
  const intl = useIntl();

  return (
    <FullWidthTabs
      labels={[
        intl.formatMessage({ id: 'settings' }),
        intl.formatMessage({ id: 'tasks' }),
        intl.formatMessage({ id: 'users' }),
      ]}
    >
      <TabContainer>
        <ApplicationSettings
          application={application}
          setApplication={setApplication}
          fetchApplication={fetchApplication}
        />
      </TabContainer>
      <TabContainer>
        {application.isDevMode ? (
          <div className={classes['dev-mode-text']}>
            <span>
              <FormattedMessage id="tasks.noTasks.inDevMode" />
            </span>
          </div>
        ) : (
          <TaskList
            id={application.id}
            application={application}
            setIsErrorMessageOpen={setIsErrorMessageOpen}
          />
        )}
      </TabContainer>
      <TabContainer>
        {application.isDevMode ? (
          <div className={classes['dev-mode-text']}>
            <span>
              <FormattedMessage id="users.noUsers.inDevMode" />
            </span>
          </div>
        ) : (
          <Users
            id={application.id}
            application={application}
            setIsErrorMessageOpen={setIsErrorMessageOpen}
          />
        )}
      </TabContainer>
    </FullWidthTabs>
  );
}
