import React from 'react';

const TaskLogPayload = ({ jsonString }) => {
  return (
    <div>
      {jsonString && (
        <pre>
          <code>{JSON.stringify(JSON.parse(jsonString), null, 2)}</code>
        </pre>
      )}
    </div>
  );
};

export default TaskLogPayload;
