import React, { useState, useEffect } from 'react';
import TooltipButton from '../../../../ui/TooltipButton';
import SpinnerButton from '../../../../ui/SpinnerButton';
import { InputGroup, FormControl } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { regenerateInviteToken } from '../../../../crud/application.crud';
import { FormattedMessage } from 'react-intl';

const InviteLink = ({ application, setIsErrorMessageOpen }) => {
  const [inviteToken, setInviteToken] = useState(application.inviteToken);

  const updateInviteToken = async () => {
    try {
      const token = await regenerateInviteToken(application.id);
      setInviteToken(token);
    } catch (e) {
      console.log(e);
      setIsErrorMessageOpen(true);
    }
  };

  const getInviteLink = inviteToken => {
    return `${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/invite/${inviteToken}`;
  };

  useEffect(() => {
    if (!application.inviteToken) {
      updateInviteToken();
    }
  }, []);

  return (
    <>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroup-sizing-sm">
            <FormattedMessage id="users.invite_link" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          readOnly
          placeholder="Invite link"
          aria-label="Invite link"
          value={getInviteLink(inviteToken)}
        />
        <InputGroup.Append>
          <CopyToClipboard text={getInviteLink(inviteToken)}>
            <TooltipButton variant="outline-secondary" tooltipText="Copied!">
              <FormattedMessage id="copy" />
            </TooltipButton>
          </CopyToClipboard>
          <SpinnerButton onClick={updateInviteToken}>
            <FormattedMessage id="update" />
          </SpinnerButton>
        </InputGroup.Append>
      </InputGroup>
    </>
  );
};

export default InviteLink;
