import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableHead } from '@material-ui/core';
import Pagination from '../../../applications/application/taskList/TaskTable/Pagination';
import { tableStyles } from '../../../applications/application/taskList/TaskTable/TasksPaginatedTable';
import { centToDollars } from '../../../../utils/textUtils';

export default function ChargesTable({ charges, count, fetchRows }) {
  const classes = tableStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage id="payments.date" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.application_name" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.monthly_price" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.users_number" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="payments.created" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="payments.cost" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {charges &&
            charges.map((charge, index) =>
              index < rowsPerPage ? (
                <TableRow key={charge.id}>
                  <TableCell component="th" scope="row" align="left">
                    {new Date(charge.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">{charge.application.name}</TableCell>
                  <TableCell align="left">
                    {centToDollars(charge.monthlyPrice)}
                  </TableCell>
                  <TableCell align="left">{charge.usersNumber}</TableCell>
                  <TableCell align="left">
                    {new Date(charge.created).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    {centToDollars(charge.cost)}
                  </TableCell>
                </TableRow>
              ) : null,
            )}
        </TableBody>
      </Table>
      <Pagination
        count={count}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetchRows={fetchRows}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}
