import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { validateEmail } from '../../../../utils/textUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { inviteUserToApplication } from '../../../../crud/application.crud';
import CustomSnackbar from '../../../../ui/CustomSnackbar';

export default function InviteUserModal({ open, setOpen, applicationId }) {
  const intl = useIntl();
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
  const handleUserInvite = () => {
    setOpen(false);
    setIsSuccessMessageOpen(true);
    setTimeout(() => {
      setIsSuccessMessageOpen(false);
    }, 3000);
  };

  return (
    <div>
      <CustomSnackbar
        open={isSuccessMessageOpen}
        setOpen={setIsSuccessMessageOpen}
        variant="success"
        text={<FormattedMessage id="application.invite.user_invited" />}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(true)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="application.invite.invite_user" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="application.invite.invite_user.description" />
          </DialogContentText>
          <Formik
            initialValues={{ email: '', firstName: '', lastName: '' }}
            onSubmit={async (values, { setStatus }) => {
              const response = await inviteUserToApplication(
                values,
                applicationId,
              );

              if (!response) {
                setStatus(intl.formatMessage({ id: 'server.error' }));
                return;
              }

              if (response.status === 201) {
                handleUserInvite();
                return;
              }

              if (response.data.errorMsg) {
                setStatus(
                  intl.formatMessage({
                    id: `application.invite.${response.data.errorMsg}`,
                  }),
                );
              }
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: 'form.validation.required',
                });
              } else if (!validateEmail(values.email)) {
                errors.email = intl.formatMessage({
                  id: 'form.validation.email_not_valid',
                });
              }

              if (!values.firstName) {
                errors.description = intl.formatMessage({
                  id: 'form.validation.required',
                });
              }
              if (!values.lastName) {
                errors.description = intl.formatMessage({
                  id: 'form.validation.required',
                });
              }

              return errors;
            }}
          >
            {props => {
              const {
                values,
                touched,
                status,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  {status && (
                    <div role="alert" className={`alert alert-danger`}>
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                  <TextField
                    margin="dense"
                    fullWidth
                    type="email"
                    label={intl.formatMessage({
                      id: 'application.invite.user_email',
                    })}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                  <TextField
                    margin="dense"
                    fullWidth
                    type="text"
                    label={intl.formatMessage({ id: 'first_name' })}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    helperText={touched.firstName && errors.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                  <TextField
                    margin="dense"
                    fullWidth
                    type="text"
                    label={intl.formatMessage({ id: 'last_name' })}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    helperText={touched.lastName && errors.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                  />
                  <div className="text-right mt-5">
                    <Button
                      className="mr-4"
                      color="primary"
                      onClick={() => setOpen(false)}
                    >
                      <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="submit" />
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
