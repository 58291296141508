import React from 'react';
import KeyValueBlock from '../../../../../ui/KeyValueBlock';
import { FormattedMessage } from 'react-intl';
import { centToDollars } from '../../../../../utils/textUtils';

export default function Total({ total }) {
  return (
    <div className="col-xl-4 col-lg-5 col-md-8 col-sm-12 align-self-end mt-4">
      <hr />
      <KeyValueBlock
        keyName={
          <strong>
            <FormattedMessage id="payments.balance_due" />
          </strong>
        }
        value={<strong>{centToDollars(total.totalBalance)}</strong>}
      />
    </div>
  );
}
