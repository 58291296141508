import React from 'react';
import { Table, TableRow, TablePagination, TableHead } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

const paginationStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TablePaginationActions({
  count,
  rowsPerPage,
  fetchRows,
  currentPage,
  setCurrentPage,
}) {
  const classes = paginationStyles();
  const theme = useTheme();

  function handleFirstPageButtonClick() {
    fetchRows(1, rowsPerPage);
    setCurrentPage(1);
  }

  function handleBackButtonClick() {
    fetchRows(currentPage - 1, rowsPerPage);
    setCurrentPage(currentPage - 1);
  }

  function handleNextButtonClick() {
    fetchRows(currentPage + 1, rowsPerPage);
    setCurrentPage(currentPage + 1);
  }

  function handleLastPageButtonClick() {
    fetchRows(Math.ceil(count / rowsPerPage), rowsPerPage);
    setCurrentPage(Math.ceil(count / rowsPerPage));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={currentPage === 1}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={currentPage === 1}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={currentPage >= Math.ceil(count / rowsPerPage)}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={currentPage >= Math.ceil(count / rowsPerPage)}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  fetchRows: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

const Pagination = ({
  count,
  rowsPerPage,
  currentPage,
  setCurrentPage,
  fetchRows,
  setRowsPerPage,
}) => {
  const classes = paginationStyles();

  function handleChangeRowsPerPage(event) {
    const value = parseInt(event.target.value, 10);

    const newPageWithCoefficient =
      (currentPage - 1) * rowsPerPage >= value
        ? Math.floor((currentPage * rowsPerPage) / value)
        : 1;

    fetchRows(newPageWithCoefficient, value);
    setCurrentPage(newPageWithCoefficient);
    setRowsPerPage(value);
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TablePagination
            className={classes.footer}
            rowsPerPageOptions={[5, 10, 25]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'Rows per page' },
              native: true,
            }}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={() => {}}
            ActionsComponent={() => (
              <TablePaginationActions
                count={count}
                rowsPerPage={rowsPerPage}
                fetchRows={fetchRows}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
          />
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default Pagination;
