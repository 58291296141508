import React, { useState, useEffect } from 'react';
import { getTasksByApplicationId } from '../../../../crud/application.crud';
import TasksPaginatedTable from './TaskTable/TasksPaginatedTable';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import NoContent from '../../../../ui/NoContent';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../../store/reducers/applicationsReducer';

export const noContentStyle = makeStyles(() => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    fontSize: '3rem',
  },
}));

export function TaskList({ id, application, setIsErrorMessageOpen }) {
  const [tasks, tasksCount] = useSelector(
    ({ applications }) => applications.tasksWithLogs,
  );
  const dispatch = useDispatch();

  const fetchTasks = (pageNumber = 1, limit = 10) => {
    dispatch(actions.fetchTasks(id, pageNumber, limit));
  };

  useEffect(() => {
    fetchTasks();
  }, [application]);

  return tasksCount > 0 ? (
    <div className="position-relative">
      <TasksPaginatedTable
        tasks={tasks}
        fetchRows={fetchTasks}
        count={tasksCount}
        setIsErrorMessageOpen={setIsErrorMessageOpen}
      />
    </div>
  ) : (
    <NoContent>
      <FormattedMessage id="tasks.no.tasks" />
    </NoContent>
  );
}
